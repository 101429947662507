<template lang="pug">
span
  confirmation-dialog(
    v-model="showing"
    error
    @okBtnClicked="$emit('submit')"
    v-if="editable"
    width="420px"
    title='Delete journey'
    :descr='descr'
  )
    template(v-slot:actions)
      v-spacer
      v-btn(color="error" text @click="updateShowing(false)" v-if="candidatesCount < 1") cancel
      v-btn(color="success" v-if="candidatesCount < 1" text @click="submit") Ok
      v-btn(color="success" v-if="candidatesCount > 0" text @click="updateShowing(false)") Ok
  v-btn(color="error" icon @click="open" v-if="editable")
    v-icon mdi-delete
</template>

<script>
import showingMixin from '@/mixins/showing.mixin'

export default {
  mixins: [showingMixin],

  props: {
    editable: Boolean,
    candidatesCount: Number
  },

  data: () => ({
    descr: ''
  }),

  methods: {
    open() {
      if (this.candidatesCount > 0) {
        this.descr = 'Journey having students can’t be deleted'
        this.updateShowing(true)
        return
      } else {
        this.descr = 'Are you sure you want to remove course journey?'
        this.updateShowing(true)
      }
    },

    submit() {
      this.updateShowing(false)
      this.$emit('submit')
    }
  },

  components: {
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue')
  }
}
</script>

<style lang="scss">
</style>
